"use strict";
import { Token } from "@uniswap/sdk-core";
import { supportedChainIdFromGQLChain } from "graphql/data/util";
import { nativeOnChain } from "uniswap/src/constants/tokens";
import { UniverseChainId } from "uniswap/src/types/chains";
import { currencyKey } from "utils/currencyKey";
import { splitHiddenTokens } from "utils/splitHiddenTokens";
function balanceComparator(a, b) {
  if (a && b) {
    return a > b ? -1 : a === b ? 0 : 1;
  } else if ((a ?? 0) > 0) {
    return -1;
  } else if ((b ?? 0) > 0) {
    return 1;
  }
  return 0;
}
function tokenComparator(balances, a, b) {
  const aAddress = currencyKey(a);
  const bAddress = currencyKey(b);
  const balanceComparison = balanceComparator(balances[aAddress]?.usdValue, balances[bAddress]?.usdValue);
  if (balanceComparison !== 0) {
    return balanceComparison;
  }
  if (a.symbol && b.symbol) {
    return a.symbol.toLowerCase() < b.symbol.toLowerCase() ? -1 : 1;
  }
  return -1;
}
export function getSortedPortfolioTokens(portfolioTokenBalances, balances, chainId, splitOptions) {
  const validVisiblePortfolioTokens = splitHiddenTokens(portfolioTokenBalances ?? [], splitOptions).visibleTokens.map((tokenBalance) => {
    const address = tokenBalance.token?.standard === "ERC20" ? tokenBalance.token?.address?.toLowerCase() : "ETH";
    if (!tokenBalance?.token?.chain || !tokenBalance.token?.decimals || !address) {
      return void 0;
    }
    const tokenChainId = supportedChainIdFromGQLChain(tokenBalance.token?.chain) ?? UniverseChainId.Mainnet;
    if (tokenChainId !== chainId) {
      return void 0;
    }
    if (address === "ETH") {
      return nativeOnChain(tokenChainId);
    }
    const portfolioToken = new Token(
      tokenChainId,
      address,
      tokenBalance.token?.decimals,
      tokenBalance.token?.symbol,
      tokenBalance.token?.project?.name ?? tokenBalance.token?.name
    );
    return portfolioToken;
  }).filter((token) => !!token);
  return validVisiblePortfolioTokens.sort(tokenComparator.bind(null, balances));
}
