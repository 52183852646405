"use strict";
import { isSupportedChainId } from "constants/chains";
import { fiatOnRampToCurrency, gqlToCurrency } from "graphql/data/util";
import { COMMON_BASES, buildCurrencyInfo } from "uniswap/src/constants/routing";
import { USDC_OPTIMISM } from "uniswap/src/constants/tokens";
import {
  ProtectionResult,
  SafetyLevel
} from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import { TokenList } from "uniswap/src/features/dataApi/types";
import { getCurrencySafetyInfo } from "uniswap/src/features/dataApi/utils";
import { isSameAddress } from "utilities/src/addresses";
import { currencyId } from "utils/currencyId";
export function gqlTokenToCurrencyInfo(token) {
  if (!token) {
    return void 0;
  }
  const currency = gqlToCurrency(token);
  if (!currency) {
    return void 0;
  }
  const currencyInfo = {
    currency,
    currencyId: currencyId(currency),
    logoUrl: token.project?.logo?.url ?? token.project?.logoUrl,
    safetyLevel: token.project?.safetyLevel ?? SafetyLevel.StrongWarning,
    isSpam: token.project?.isSpam ?? false,
    safetyInfo: getCurrencySafetyInfo(token.project?.safetyLevel, token.protectionInfo)
  };
  return currencyInfo;
}
export function meldSupportedCurrencyToCurrencyInfo(forCurrency) {
  if (!isSupportedChainId(Number(forCurrency.chainId))) {
    return;
  }
  const supportedChainId = Number(forCurrency.chainId);
  const commonBases = COMMON_BASES[supportedChainId];
  const currencyInfo = commonBases.find((base) => {
    if (base.currency.isNative) {
      return !forCurrency.address;
    }
    return isSameAddress(base.currency.address, forCurrency.address);
  });
  if (currencyInfo) {
    return {
      ...currencyInfo,
      logoUrl: forCurrency.symbol
    };
  }
  if (isSameAddress(forCurrency.address, "0x7f5c764cbc14f9669b88837ca1490cca17c31607")) {
    return buildCurrencyInfo(USDC_OPTIMISM);
  }
  const currency = fiatOnRampToCurrency(forCurrency);
  if (!currency) {
    return;
  }
  return {
    currency,
    currencyId: currencyId(currency),
    logoUrl: forCurrency.symbol,
    safetyLevel: SafetyLevel.Verified,
    safetyInfo: {
      tokenList: TokenList.Default,
      protectionResult: ProtectionResult.Benign
    },
    isSpam: false
  };
}
