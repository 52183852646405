"use strict";
import { showTestnetsAtom } from "components/AccountDrawer/TestnetsToggle";
import { TESTNET_CHAIN_IDS, getChainPriority, useIsSupportedChainIdCallback } from "constants/chains";
import { useConnectedWalletSupportedChains } from "hooks/useConnectedWalletSupportedChains";
import { useAtomValue } from "jotai/utils";
import { useMemo } from "react";
import { ALL_CHAIN_IDS } from "uniswap/src/constants/chains";
export function useSupportedChainIds() {
  const walletSupportsChain = useConnectedWalletSupportedChains();
  const isSupportedChain = useIsSupportedChainIdCallback();
  const showTestnets = useAtomValue(showTestnetsAtom);
  return useMemo(() => {
    const { supported, unsupported } = ALL_CHAIN_IDS.filter((chain) => {
      return isSupportedChain(chain) && (showTestnets || !TESTNET_CHAIN_IDS.includes(chain));
    }).sort((a, b) => getChainPriority(a) - getChainPriority(b)).reduce(
      (acc, chain) => {
        if (walletSupportsChain.includes(chain)) {
          acc.supported.push(chain);
        } else {
          acc.unsupported.push(chain);
        }
        return acc;
      },
      { supported: [], unsupported: [] }
    );
    return { supported, unsupported };
  }, [isSupportedChain, showTestnets, walletSupportsChain]);
}
