export enum WalletEventName {
  GasEstimateAccuracy = 'Gas Estimate Accuracy',
  ExploreSearchCancel = 'Explore Search Cancel',
  NFTVisibilityChanged = 'NFT Visibility Changed',
  NFTsLoaded = 'NFTs Loaded',
  NetworkFilterSelected = 'Network Filter Selected',
  ExternalLinkOpened = 'External Link Opened',
  PerformanceGraphql = 'Performance GraphQL',
  PortfolioBalanceFreshnessLag = 'Portfolio Balance Freshness Lag',
  SendRecipientSelected = 'Send Recipient Selected',
  ShareButtonClicked = 'Share Button Clicked',
  SwapSubmitted = 'Swap Submitted to Provider',
  TokenVisibilityChanged = 'Token Visibility Changed',
  TransferCompleted = 'Transfer Completed',
  TransferSubmitted = 'Transfer Submitted',
  ViewRecoveryPhrase = 'View Recovery Phrase',
  WalletAdded = 'Wallet Added',
  WalletRemoved = 'Wallet Removed',
}
