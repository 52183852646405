/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum BridgeChainId {
    '_1' = 1,
    '_10' = 10,
    '_137' = 137,
    '_8453' = 8453,
    '_42161' = 42161,
}
