"use strict";
import { loadingOpacityMixin } from "components/Loader/styled";
import styled from "lib/styled-components";
import React, { forwardRef } from "react";
import { escapeRegExp } from "utils/escapeRegExp";
import { useFormatterLocales } from "utils/formatNumbers";
const StyledInput = styled.input`
  color: ${({ error, theme }) => error ? theme.critical : theme.neutral1};
  pointer-events: ${({ disabled }) => disabled ? "none" : "auto"};
  width: 0;
  position: relative;
  font-weight: 485;
  outline: none;
  border: none;
  flex: 1 1 auto;
  background-color: transparent;
  font-size: ${({ fontSize }) => fontSize ?? "28px"};
  text-align: ${({ align }) => align && align};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px;
  -webkit-appearance: textfield;
  text-align: right;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  [type='number'] {
    -moz-appearance: textfield;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.neutral3};
  }
`;
function localeUsesComma(locale) {
  const decimalSeparator = new Intl.NumberFormat(locale).format(1.1)[1];
  return decimalSeparator === ",";
}
const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`);
export function isInputGreaterThanDecimals(value, maxDecimals) {
  const decimalGroups = value.split(".");
  return !!maxDecimals && decimalGroups.length > 1 && decimalGroups[1].length > maxDecimals;
}
const Input = forwardRef(
  ({ value, onUserInput, placeholder, prependSymbol, maxDecimals, testId, ...rest }, ref) => {
    const { formatterLocale } = useFormatterLocales();
    const enforcer = (nextUserInput) => {
      if (nextUserInput === "" || inputRegex.test(escapeRegExp(nextUserInput))) {
        if (isInputGreaterThanDecimals(nextUserInput, maxDecimals)) {
          return;
        }
        onUserInput(nextUserInput);
      }
    };
    const formatValueWithLocale = (value2) => {
      const [searchValue, replaceValue] = localeUsesComma(formatterLocale) ? [/\./g, ","] : [/,/g, "."];
      return value2.toString().replace(searchValue, replaceValue);
    };
    const valueFormattedWithLocale = formatValueWithLocale(value);
    return <StyledInput
      {...rest}
      ref={ref}
      value={prependSymbol && value ? prependSymbol + valueFormattedWithLocale : valueFormattedWithLocale}
      data-testid={testId}
      onChange={(event) => {
        if (prependSymbol) {
          const value2 = event.target.value;
          const formattedValue = value2.toString().includes(prependSymbol) ? value2.toString().slice(prependSymbol.length, value2.toString().length + 1) : value2;
          enforcer(formattedValue.replace(/,/g, "."));
        } else {
          enforcer(event.target.value.replace(/,/g, "."));
        }
      }}
      inputMode="decimal"
      autoComplete="off"
      autoCorrect="off"
      type="text"
      pattern="^[0-9]*[.,]?[0-9]*$"
      placeholder={placeholder || "0"}
      minLength={1}
      maxLength={79}
      spellCheck="false"
    />;
  }
);
Input.displayName = "Input";
const MemoizedInput = React.memo(Input);
export { MemoizedInput as Input };
export const StyledNumericalInput = styled(MemoizedInput)`
  ${loadingOpacityMixin};
  text-align: left;
  font-size: 36px;
  font-weight: 485;
  max-height: 44px;
`;
