"use strict";
import { CONNECTION } from "components/Web3Provider/constants";
import { useAccount } from "hooks/useAccount";
import { ALL_CHAIN_IDS } from "uniswap/src/constants/chains";
export function useConnectedWalletSupportedChains() {
  const { connector } = useAccount();
  switch (connector?.type) {
    case CONNECTION.UNISWAP_WALLET_CONNECT_CONNECTOR_ID:
    case CONNECTION.WALLET_CONNECT_CONNECTOR_ID:
      return connector.getNamespaceChainsIds?.().length ? connector.getNamespaceChainsIds?.() : ALL_CHAIN_IDS;
    default:
      return ALL_CHAIN_IDS;
  }
}
