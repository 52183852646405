"use strict";
export const migration18 = (state) => {
  if (!state?.userSettings) {
    return;
  }
  const newState = { ...state };
  newState.userSettings.currentLanguage ??= "en";
  newState.userSettings.currentCurrency ??= "USD";
  return { ...newState, _persist: { ...state._persist, version: 18 } };
};
