"use strict";
import { PortfolioLogo } from "components/AccountDrawer/MiniPortfolio/PortfolioLogo";
import styled from "lib/styled-components";
import { UniverseChainId } from "uniswap/src/types/chains";
const LogoContainer = styled.div`
  position: relative;
  display: flex;
`;
export default function AssetLogo({
  currency,
  chainId = UniverseChainId.Mainnet,
  size = 24,
  style,
  loading
}) {
  return <LogoContainer style={{ height: size, width: size, ...style }}><PortfolioLogo currencies={currency ? [currency] : []} size={size} chainId={chainId} loading={loading} /></LogoContainer>;
}
