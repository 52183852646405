"use strict";
import { MaxUint256, permit2Address } from "@uniswap/permit2-sdk";
import { RPC_PROVIDERS } from "constants/providers";
import ERC20_ABI from "uniswap/src/abis/erc20.json";
import WETH_ABI from "uniswap/src/abis/weth.json";
import { WRAPPED_NATIVE_CURRENCY } from "uniswap/src/constants/tokens";
import { UniverseChainId } from "uniswap/src/types/chains";
import { getContract } from "utilities/src/contracts/getContract";
const WRAP_FALLBACK_GAS_LIMIT = 45e3;
const APPROVE_FALLBACK_GAS_LIMIT = 65e3;
export async function getApproveInfo(account, currency, amount, usdCostPerGas) {
  if (currency.isNative) {
    return { needsApprove: false };
  }
  if (!account || !usdCostPerGas) {
    return { needsApprove: false };
  }
  if (currency.chainId === UniverseChainId.ArbitrumOne || currency.chainId === UniverseChainId.ArbitrumGoerli) {
    return { needsApprove: false };
  }
  const provider = RPC_PROVIDERS[currency.chainId];
  const tokenContract = getContract(currency.address, ERC20_ABI, provider);
  let approveGasUseEstimate;
  try {
    const allowance = await tokenContract.callStatic.allowance(account, permit2Address(currency.chainId));
    if (allowance.gte(amount)) {
      return { needsApprove: false };
    }
  } catch (_) {
    return { needsApprove: false };
  }
  try {
    const approveTx = await tokenContract.populateTransaction.approve(permit2Address(currency.chainId), MaxUint256);
    approveGasUseEstimate = (await provider.estimateGas({ from: account, ...approveTx })).toNumber();
  } catch (_) {
    approveGasUseEstimate = APPROVE_FALLBACK_GAS_LIMIT;
  }
  return { needsApprove: true, approveGasEstimateUSD: approveGasUseEstimate * usdCostPerGas };
}
export async function getWrapInfo(needsWrap, account, chainId, amount, usdCostPerGas) {
  if (!needsWrap) {
    return { needsWrap: false };
  }
  const provider = RPC_PROVIDERS[chainId];
  const wethAddress = WRAPPED_NATIVE_CURRENCY[chainId]?.address;
  if (!wethAddress || !usdCostPerGas) {
    return { needsWrap: false };
  }
  let wrapGasUseEstimate;
  try {
    const wethContract = getContract(wethAddress, WETH_ABI, provider, account);
    const wethTx = await wethContract.populateTransaction.deposit({ value: amount });
    wrapGasUseEstimate = (await provider.estimateGas({ from: account, ...wethTx })).toNumber();
  } catch (_) {
    wrapGasUseEstimate = WRAP_FALLBACK_GAS_LIMIT;
  }
  return { needsWrap: true, wrapGasEstimateUSD: wrapGasUseEstimate * usdCostPerGas };
}
