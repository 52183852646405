"use strict";
import { AnalyticsToggle } from "components/AccountDrawer/AnalyticsToggle";
import { GitVersionRow } from "components/AccountDrawer/GitVersionRow";
import { SlideOutMenu } from "components/AccountDrawer/SlideOutMenu";
import { SmallBalanceToggle } from "components/AccountDrawer/SmallBalanceToggle";
import { SpamToggle } from "components/AccountDrawer/SpamToggle";
import { TestnetsToggle } from "components/AccountDrawer/TestnetsToggle";
import Column from "components/deprecated/Column";
import Row from "components/deprecated/Row";
import { LOCALE_LABEL } from "constants/locales";
import { useActiveLocalCurrency } from "hooks/useActiveLocalCurrency";
import { useActiveLocale } from "hooks/useActiveLocale";
import styled from "lib/styled-components";
import { ChevronRight } from "react-feather";
import { ClickableStyle, ThemedText } from "theme/components";
import ThemeToggle from "theme/components/ThemeToggle";
import { Trans } from "uniswap/src/i18n";
const Container = styled(Column)`
  height: 100%;
  justify-content: space-between;
`;
const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: '10px';
`;
const SettingsButtonWrapper = styled(Row)`
  ${ClickableStyle}
  padding: 16px 0px;
`;
const StyledChevron = styled(ChevronRight)`
  color: ${({ theme }) => theme.neutral2};
`;
const LanguageLabel = styled(Row)`
  white-space: nowrap;
`;
const SettingsButton = ({
  title,
  currentState,
  onClick,
  testId
}) => <SettingsButtonWrapper data-testid={testId} align="center" justify="space-between" onClick={onClick}>
  <ThemedText.SubHeaderSmall color="textPrimary">{title}</ThemedText.SubHeaderSmall>
  <LanguageLabel gap="xs" align="center" width="min-content">
    <ThemedText.LabelSmall color="textPrimary">{currentState}</ThemedText.LabelSmall>
    <StyledChevron size={20} />
  </LanguageLabel>
</SettingsButtonWrapper>;
export default function SettingsMenu({
  onClose,
  openLanguageSettings,
  openLocalCurrencySettings
}) {
  const activeLocale = useActiveLocale();
  const activeLocalCurrency = useActiveLocalCurrency();
  return <SlideOutMenu title={<Trans i18nKey="common.settings" />} onClose={onClose}><Container>
    <div>
      <ToggleWrapper>
        <ThemeToggle />
        <SmallBalanceToggle />
        <SpamToggle />
        <AnalyticsToggle />
        <TestnetsToggle />
      </ToggleWrapper>
      <Column>
        <SettingsButton
          title={<Trans i18nKey="common.language" />}
          currentState={LOCALE_LABEL[activeLocale]}
          onClick={openLanguageSettings}
          testId="language-settings-button"
        />
        <SettingsButton
          title={<Trans i18nKey="common.currency" />}
          currentState={activeLocalCurrency}
          onClick={openLocalCurrencySettings}
          testId="local-currency-settings-button"
        />
      </Column>
    </div>
    <GitVersionRow />
  </Container></SlideOutMenu>;
}
